@use "sass:math"

$orange: #f0833b

.header
  height: 142px
  background-color: #ffffff


.header-logo
  display: block
  text-decoration: none
  width: 164px
  margin: 32px 0 0 11px


.ts-header-container
  max-width: 1920px
  margin: 0 auto
  padding-left: 70px
  padding-right: 70px


.nav
  height: 48px
  line-height: 46px
  background-color: #0097ff
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 0 2px 2px rgba(0,0,0,0.05), 0 4px 4px rgba(0,0,0,0.05), 0 8px 8px rgba(0,0,0,0.05), 0 16px 16px rgba(0,0,0,0.05), 0 32px 32px rgba(0,0,0,0.05)
  margin-bottom: 24px
  > .ant-menu-light
    background: #0097ff
    border-bottom: none
    > .ant-menu-item
      font-weight: 600
      font-size: 13px
      line-height: 44px
      text-transform: uppercase
      color: #ffffff
      border-top: 2px solid transparent
      height: 48px
      border-bottom: none
    > .ant-menu-item a
      color: #ffffff
    > .ant-menu-item a:hover
      color: #0097ff
    > .ant-menu-item-active
      background: #ffffff
      color: $orange
      border-color: $orange
      border-top: 2px solid
      border-bottom: none
    > .ant-menu-submenu
      font-weight: 600
      font-size: 13px
      line-height: 44px
      text-transform: uppercase
      color: #ffffff
      border-top: 2px solid transparent
      height: 48px
      border-bottom: none
    > .ant-menu-submenu-active
      background: #ffffff
      color: $orange
      border-color: $orange
      border-top: 2px solid
      border-bottom: none



.nav-menu
  text-align: center


.ts-portal-card
  border-top: 2px solid $orange
  background-color: #ffffff
  padding: 16px
  margin: 6px
  box-shadow: 0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.04), 0 8px 8px rgba(0,0,0,0.05)
  min-height: 150px


.ts-address-card
  border-top: 2px solid $orange
  background-color: #ffffff
  padding: 16px
  margin: 16px
  box-shadow: 0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.04), 0 8px 8px rgba(0,0,0,0.05)
  min-height: 150px



.ts-home-content-container
  max-width: 1300px
  margin: 0 auto


.ts-rma-content-container
  max-width: 800px
  margin: 0 auto


.ts-content
  margin-bottom: 48px


.ts-footer
  background: #0f466c
  color: #ffffff


.ts-home-menu
  border-right: none


.login-form
  max-width: 400px

.login-form-forgot
  float: right

.ts-full-width
  width: 100%

.login-alert
  margin-bottom: 16px

.ts-form-margin
  margin-top: 16px


.ts-pdf-modal
  width: 95% !important
  height: 95vh !important
  > .ant-modal-content
    height: 100%
    > .ant-modal-body
      height: calc(100% - 130px)

.ts-pdf
  width: 100%
  height: 100%


.ts-card-dark-border
  border-color: #b4b4b4


.ts-pre-wrap
  white-space: pre-wrap

.payment_fields
  height: 32px

.payment_form_item
  margin-bottom: 16px

.spacer_right
  margin-right: 16px
